import React from 'react'
import Logo from '../components/Logo'
import Headline from '../components/Headline'
import Layout from '../components/Layout'
import Section from '../components/Section'
import Seo from '../components/Seo'
import { seo, intro } from '../content/platnosci'
import { useLangContext } from '../context/lang.context'
import { s } from '../style'
import FormPayment from '../components/FormPayment'

const Platnosci = () => {
  const { lang } = useLangContext()
  return (
    <Layout>
      <Seo
        title={seo.title[lang]}
        desc={seo.desc[lang]}
        url={seo.url}
        noIndex
      />
      <div css={sSpacing} />
      <Section paddingBottom paddingTop>
        <Headline h={1} desc={intro.desc[lang]} large gradient>
          <span css={sTitle}>
            <Logo /> {intro.title[lang]}
          </span>
        </Headline>
      </Section>
      <FormPayment formType='PAYMENT' />
      <div css={sSpacing} />
    </Layout>
  )
}

const sSpacing = {
  padding: '4rem 0',
  [s.sm_down]: { padding: '2rem 0' },
}

const sTitle = {
  display: 'flex',
  flexDirection: 'column',
  // fontFamily: 'Bank Gothic',
  // textTransform: 'uppercase',
  fontWeight: 700,
  lineHeight: '1em',
  letterSpacing: '-0.05em',

  svg: {
    position: 'relative',
    top: -12,
    left: 8,
  },

  [s.xs]: {
    fontSize: '4rem',
    svg: {
      top: -12,
      left: 4,
    },
  },
  [s.ss]: {
    fontSize: '3.5rem',
  },
  [s.sm]: {
    fontSize: '5rem',
  },
  [s.lg]: {
    fontSize: '6rem',
  },
}

export default Platnosci
